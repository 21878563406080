<template>
  <div class="home">
      <Header></Header>
       <div class="skills-main-box">
           <div class="skills-banner-box">
               <div class="top-tit-a">
                   <p class="p1">ZUI使用技巧</p>
                   <span class="shu">|</span>
                   <p class="p1">一视界</p>
               </div>
               <div class="banner-img">
                   <img src="../assets/img/skills-banner.jpg" alt="">
               </div>
               <div class="big-tit">ZUI 使用技巧</div>
               <div class="slide-ul">
                   <div class="slide-box">
                       <div class="slide-li">
                           <div class="top-img">
                               <img src="../assets/img/skills-logo2.png" style="width:.52rem;top:.08rem" class="img1" alt="">
                               <img src="../assets/img/skills-logo22.png" style="width:.52rem;top:.08rem"  class="img2" alt="">
                           </div>
                           <p class="bottom-p">全部</p>
                       </div>
                       <div class="slide-li">
                           <div class="top-img">
                               <img src="../assets/img/skills-logo3.png" style="width:.49rem"  class="img1" alt="">
                               <img src="../assets/img/skills-logo32.png" style="width:.49rem"  class="img2" alt="">
                           </div>
                           <p class="bottom-p">一视界</p>
                       </div>
                       <div class="slide-li">
                           <div class="top-img">
                               <img src="../assets/img/skills-logo1.png" style="width:.53rem;top:.1rem"  class="img1" alt="">
                               <img src="../assets/img/skills-logo12.png" style="width:.53rem;top:.1rem"  class="img2" alt="">
                           </div>
                           <p class="bottom-p">超级互联</p>
                       </div>
                       <div class="slide-li">
                           <div class="top-img">
                               <img src="../assets/img/skills-logo4.png" style="width:.47rem"  class="img1" alt="">
                               <img src="../assets/img/skills-logo42.png" style="width:.47rem" class="img2" alt="">
                           </div>
                           <p class="bottom-p">拯救者领域</p>
                       </div>
                       <div class="move-line"></div>
                   </div>
               </div>
           </div>
           <div class="skills-content">
               <div class="content-select-input">
                   <select>
                        <option value ="volvo">所有系统版本</option>
                        <option value ="saab">所有系统版本</option>
                        <option value="opel">所有系统版本</option>
                        <option value="audi">所有系统版本</option>
                    </select>
               </div>
               <div class="content-box">
                   <div class="content-slide">
                       <div class="top-box-video">
                           <div class="box-text">
                               <p class="p1">锁屏状态</p>
                               <p class="p2">如何快速启动应用?</p>
                           </div>
                           <div class="block-bg">
                               <div class="block-bg-rel">
                                   <div class="video-logo">
                                       <img src="../assets/img/skills-video-logo.png" alt="">
                                   </div>
                                   <div class="time-p">00:46</div>
                               </div>
                           </div>
                       </div>
                       <div class="bottom-text-main">
                           <div class="top-logo-text">
                               <p class="name-logo">ZUI</p>
                               <p class="time">2020-12-28</p>
                           </div>
                           <p class="big-p">指纹速启</p>
                           <p class="two-p">[指纹速启]，启动应用快人一步，便捷生活无需等待。</p>
                           <p class="big-p">支持机型</p>
                           <div class="about-slide">
                               <div class="slide">小新 Pad 2022</div>
                               <div class="slide">小新 Pad Pro 12.6</div>
                           </div>
                       </div>
                   </div>
                   <div class="content-slide">
                       <div class="top-box-video">
                           <div class="box-text">
                               <p class="p1">锁屏状态</p>
                               <p class="p2">如何快速启动应用?</p>
                           </div>
                           <div class="block-bg">
                               <div class="block-bg-rel">
                                   <div class="video-logo">
                                       <img src="../assets/img/skills-video-logo.png" alt="">
                                   </div>
                                   <div class="time-p">00:46</div>
                               </div>
                           </div>
                       </div>
                       <div class="bottom-text-main">
                           <div class="top-logo-text">
                               <p class="name-logo">ZUI</p>
                               <p class="time">2020-12-28</p>
                           </div>
                           <p class="big-p">指纹速启</p>
                           <p class="two-p">[指纹速启]，启动应用快人一步，便捷生活无需等待。</p>
                           <p class="big-p">支持机型</p>
                           <div class="about-slide">
                               <div class="slide">小新 Pad 2022</div>
                               <div class="slide">小新 Pad Pro 12.6</div>
                           </div>
                       </div>
                   </div>
                   <div class="content-slide">
                       <div class="top-box-video">
                           <div class="box-text">
                               <p class="p1">锁屏状态</p>
                               <p class="p2">如何快速启动应用?</p>
                           </div>
                           <div class="block-bg">
                               <div class="block-bg-rel">
                                   <div class="video-logo">
                                       <img src="../assets/img/skills-video-logo.png" alt="">
                                   </div>
                                   <div class="time-p">00:46</div>
                               </div>
                           </div>
                       </div>
                       <div class="bottom-text-main">
                           <div class="top-logo-text">
                               <p class="name-logo">ZUI</p>
                               <p class="time">2020-12-28</p>
                           </div>
                           <p class="big-p">指纹速启</p>
                           <p class="two-p">[指纹速启]，启动应用快人一步，便捷生活无需等待。</p>
                           <p class="big-p">支持机型</p>
                           <div class="about-slide">
                               <div class="slide">小新 Pad 2022</div>
                               <div class="slide">小新 Pad Pro 12.6</div>
                           </div>
                       </div>
                   </div>
                   <div class="content-slide">
                       <div class="top-box-video">
                           <div class="box-text">
                               <p class="p1">锁屏状态</p>
                               <p class="p2">如何快速启动应用?</p>
                           </div>
                           <div class="block-bg">
                               <div class="block-bg-rel">
                                   <div class="video-logo">
                                       <img src="../assets/img/skills-video-logo.png" alt="">
                                   </div>
                                   <div class="time-p">00:46</div>
                               </div>
                           </div>
                       </div>
                       <div class="bottom-text-main">
                           <div class="top-logo-text">
                               <p class="name-logo">ZUI</p>
                               <p class="time">2020-12-28</p>
                           </div>
                           <p class="big-p">指纹速启</p>
                           <p class="two-p">[指纹速启]，启动应用快人一步，便捷生活无需等待。</p>
                           <p class="big-p">支持机型</p>
                           <div class="about-slide">
                               <div class="slide">小新 Pad 2022</div>
                               <div class="slide">小新 Pad Pro 12.6</div>
                           </div>
                       </div>
                   </div>
                   <div class="content-slide">
                       <div class="top-box-video">
                           <div class="box-text">
                               <p class="p1">锁屏状态</p>
                               <p class="p2">如何快速启动应用?</p>
                           </div>
                           <div class="block-bg">
                               <div class="block-bg-rel">
                                   <div class="video-logo">
                                       <img src="../assets/img/skills-video-logo.png" alt="">
                                   </div>
                                   <div class="time-p">00:46</div>
                               </div>
                           </div>
                       </div>
                       <div class="bottom-text-main">
                           <div class="top-logo-text">
                               <p class="name-logo">ZUI</p>
                               <p class="time">2020-12-28</p>
                           </div>
                           <p class="big-p">指纹速启</p>
                           <p class="two-p">[指纹速启]，启动应用快人一步，便捷生活无需等待。</p>
                           <p class="big-p">支持机型</p>
                           <div class="about-slide">
                               <div class="slide">小新 Pad 2022</div>
                               <div class="slide">小新 Pad Pro 12.6</div>
                           </div>
                       </div>
                   </div>
                   <div class="content-slide">
                       <div class="top-box-video">
                           <div class="box-text">
                               <p class="p1">锁屏状态</p>
                               <p class="p2">如何快速启动应用?</p>
                           </div>
                           <div class="block-bg">
                               <div class="block-bg-rel">
                                   <div class="video-logo">
                                       <img src="../assets/img/skills-video-logo.png" alt="">
                                   </div>
                                   <div class="time-p">00:46</div>
                               </div>
                           </div>
                       </div>
                       <div class="bottom-text-main">
                           <div class="top-logo-text">
                               <p class="name-logo">ZUI</p>
                               <p class="time">2020-12-28</p>
                           </div>
                           <p class="big-p">指纹速启</p>
                           <p class="two-p">[指纹速启]，启动应用快人一步，便捷生活无需等待。</p>
                           <p class="big-p">支持机型</p>
                           <div class="about-slide">
                               <div class="slide">小新 Pad 2022</div>
                               <div class="slide">小新 Pad Pro 12.6</div>
                           </div>
                       </div>
                   </div>
                   <div class="content-slide">
                       <div class="top-box-video">
                           <div class="box-text">
                               <p class="p1">锁屏状态</p>
                               <p class="p2">如何快速启动应用?</p>
                           </div>
                           <div class="block-bg">
                               <div class="block-bg-rel">
                                   <div class="video-logo">
                                       <img src="../assets/img/skills-video-logo.png" alt="">
                                   </div>
                                   <div class="time-p">00:46</div>
                               </div>
                           </div>
                       </div>
                       <div class="bottom-text-main">
                           <div class="top-logo-text">
                               <p class="name-logo">ZUI</p>
                               <p class="time">2020-12-28</p>
                           </div>
                           <p class="big-p">指纹速启</p>
                           <p class="two-p">[指纹速启]，启动应用快人一步，便捷生活无需等待。</p>
                           <p class="big-p">支持机型</p>
                           <div class="about-slide">
                               <div class="slide">小新 Pad 2022</div>
                               <div class="slide">小新 Pad Pro 12.6</div>
                           </div>
                       </div>
                   </div>
                   <div class="content-slide">
                       <div class="top-box-video">
                           <div class="box-text">
                               <p class="p1">锁屏状态</p>
                               <p class="p2">如何快速启动应用?</p>
                           </div>
                           <div class="block-bg">
                               <div class="block-bg-rel">
                                   <div class="video-logo">
                                       <img src="../assets/img/skills-video-logo.png" alt="">
                                   </div>
                                   <div class="time-p">00:46</div>
                               </div>
                           </div>
                       </div>
                       <div class="bottom-text-main">
                           <div class="top-logo-text">
                               <p class="name-logo">ZUI</p>
                               <p class="time">2020-12-28</p>
                           </div>
                           <p class="big-p">指纹速启</p>
                           <p class="two-p">[指纹速启]，启动应用快人一步，便捷生活无需等待。</p>
                           <p class="big-p">支持机型</p>
                           <div class="about-slide">
                               <div class="slide">小新 Pad 2022</div>
                               <div class="slide">小新 Pad Pro 12.6</div>
                           </div>
                       </div>
                   </div>
               </div>
               <div class="see-more">查看更多</div>
           </div>
       </div>
    <Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 38.4 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 38.4 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios';
import Header from "../components/Header"
import Footer from "../components/Footer"
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      //所有新闻
      allMsg:[],
      //首页banner
      bannerdata:"",
      //最新消息列表
      newMsg:[],
      //更新信息banner
      updatedata:[
        {coverPicture:''}
      ],
      rightMoreArr:[],
      bannerUrl:'',
      pageNum:1,
      pageSize:20,
      searchText:'',
      leftNavList:[],
      leftNavListStatus:false,
      showIndex:0,
      //左侧导航code
      leftCode:'',
      leftValue:"",
      navigators:[],
      allNavigators:[],
    };
  },
  beforeMount: function () {
   
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {

  },
};
</script>
<style lang="less" scoped> 

.skills-main-box{
  width: 100%;
  min-height: calc(100vh - 3rem);
  overflow: hidden;
  background: #ffffff;
  position: relative;
  padding-top: .95rem;
  box-sizing: border-box;
}
.skills-main-box img{
	display: block;
	width: 100%;
}
.skills-main-box .skills-banner-box{
    position: relative;
    width: 100%;
    height:7.3rem;
    overflow: hidden;
}
.skills-main-box .skills-banner-box .top-tit-a{
    font-size:.24rem;
    color:#000000;
    overflow: hidden;
    display: flex;
    position: absolute;
    top:.6rem;
    left:7.2rem;
}
.skills-main-box .skills-banner-box .top-tit-a .p1{
    font-size:.24rem;
    color:#000000;
}
.skills-main-box .skills-banner-box .top-tit-a .shu{
    display: inline-block;
    padding: 0 .2rem;
    font-size:.24rem;
    color:#000000;
}
.skills-main-box .skills-banner-box .big-tit{
    position: absolute;
    top:2.15rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size:1rem;
    color: #000000;
    font-weight: 600;
}
.skills-main-box .skills-banner-box .slide-ul{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 1.5rem;
    display: flex;
    justify-content: center;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box{
   width: auto;
   margin: 0 auto;
   overflow: hidden;
   height: 100%;
   position: relative;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li{
    float: left;
    width: 1.9rem;
    height: 100%;
    margin-left: 1rem;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li:nth-of-type(1){
    margin-left: 0;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img{
    width: 100%;
    height:.8rem;
    overflow: hidden;
    position: relative;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img img{
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img .img1{
    display: block;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img .img2{
    display: none;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .bottom-p{
    width: 100%;
    text-align: center;
    font-size:.3rem;
    color: #999999;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .move-line{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1.9rem;
    height:.1rem;
    background: #5f7bfd;
}
.no-msg{
  width: 180px !important;
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 75px;
}
.no-msg img{
  width: 100%;
}

.skills-content{
    padding:1.5rem 0;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}
.skills-content .content-select-input select{
    border: none;
    font-size: 15px;
}
.skills-content .content-box{
    width: 100%;
    overflow: hidden;
}
.skills-content .content-box .content-slide{
    float: left;
    width: 280px;
    margin-left:25px;
    margin-top: 26px;
    border-radius: 10px;
    background: #f5f5f6;
    cursor: pointer;
    overflow: hidden;
    padding-bottom:10px;
}
.skills-content .content-box .content-slide:nth-of-type(4n+1){
    margin-left: 0;
}
.skills-content .content-box .content-slide .top-box-video{
    position: relative;
    width: 100%;
    height:200px;
    background:#ebebed;
}
.skills-content .content-box .content-slide .top-box-video .box-text{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translate(0,-50%);
    overflow: hidden;
}
.skills-content .content-box .content-slide .top-box-video .box-text .p1{
    font-size:24px;
    color:#7a6afb;
}
.skills-content .content-box .content-slide .top-box-video .box-text .p2{
    font-size:24px;
    color:#000000;
}
.skills-content .content-box .content-slide .top-box-video .block-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: none;
}
.skills-content .content-box .content-slide:hover .top-box-video .block-bg{
    display: block;
}
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel{
    position: relative;
    width: 100%;
    height: 100%;
}
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel .video-logo{
    position: absolute;
    top:146px;
    left:15px;
    width: 40px;
    height: 40px;
}
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel .time-p{
    position: absolute;
    top:157px;
    left:63px;
    color: #ffffff;
    font-size:15px;
}
.skills-content .content-box .content-slide .bottom-text-main{
    width: 100%;
    padding:10px 15px;
    overflow: hidden;
}
.skills-content .content-box .content-slide .bottom-text-main .top-logo-text{
    width: 100%;
    overflow: hidden;
}
.skills-content .content-box .content-slide .bottom-text-main .top-logo-text .name-logo{
    float: left;
    padding:4px 8px;
    border-radius: 4px;
    background:#5f7bfd;
    font-size:12px;
    color: #ffffff;
}
.skills-content .content-box .content-slide .bottom-text-main .top-logo-text .time{
    float: left;
    margin-left:6px;
    line-height:24px;
    font-size:12px;
    color:#999999;
}
.skills-content .content-box .content-slide .bottom-text-main .big-p{
    margin-top:13px;
    font-size:13px;
    font-weight: 600;
    color: #000000;
}
.skills-content .content-box .content-slide .bottom-text-main .two-p{
    margin-top:9px;
    color:#666666;
    font-size: 10px;
}
.skills-content .content-box .content-slide .bottom-text-main .about-slide{
    overflow: hidden;
    margin-top:10px;
}
.skills-content .content-box .content-slide .bottom-text-main .about-slide .slide{
    float: left;
    padding:5px 10px;
    margin-left:7px;
    background: #ffffff;
    font-size:10px;
    color:#666666;
}
.skills-content .content-box .content-slide .bottom-text-main .about-slide .slide:nth-of-type(2n+1){
    margin-left: 0;
}
.skills-content .see-more{
    margin: 0 auto;
    margin-top:50px;
    width:78px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size:12px;
    color:#000000;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
}
</style>